export function useSidesheetHashHandler(hash: number, isSidesheetOpen: Ref<boolean>, isOpeningAllowed = true) {
  function clearUrlHash() {
    // Backup for browsers that don't support history.replaceState
    if ('pushState' in history) {
      history.replaceState('', document.title, window.location.pathname + window.location.search)
    }
    else {
      const scrollV = document.body.scrollTop

      window.location.hash = ''

      document.body.scrollTop = scrollV
    }
  }

  onMounted(() => {
    if (window.location.hash === `#${hash}`) {
      if (isOpeningAllowed) {
        isSidesheetOpen.value = true
      }
      else {
        clearUrlHash()
      }
      return
    }

    isSidesheetOpen.value = false
  })

  watch(isSidesheetOpen, () => {
    if (isSidesheetOpen.value) {
      window.location.hash = String(hash)
      return
    }

    clearUrlHash()
  })
}
