<script lang="ts" setup>
import { CfgButton, CfgSlideOver, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { Message2Icon } from '@cfgtech/icons'
import { APP_DATE_FORMAT } from '~/constants/common'
import type { Company, Issue } from '~/api/types'
import type { News } from '~/api/types/generated/components/News'

const DEFAULT_NEWS_NUMBER = 1

// eslint-disable-next-line vue/define-macros-order
const props = withDefaults(defineProps<{
  issue?: Issue | null
  company?: Company | null
  id: string
  newsNumber?: number
}>(), {
  newsNumber: DEFAULT_NEWS_NUMBER,
  issue: null,
  company: null,
})
const MORE_NEWS = 3

const issue = toRef(props, 'issue')
const company = toRef(props, 'company')
const isDetailOpened = ref(false)
const maxNews = ref(props.newsNumber)
const openedDetail = ref<News>()
const issueName = ref(issue.value?.name)
const companyName = ref(company.value?.name)

const { t } = useI18n()
const responsive = useResponsive()
const news = computed(() => issue.value ? issue.value?.company?.data?.attributes?.news : company.value?.news)
const sectionTitle = computed(() => `${t('news.title')} ${issue.value ? issue.value?.company?.data?.attributes?.name : ''}`)
const lineClampByDevice = computed(() => !responsive.value.mobile ? 'line-clamp-2' : '!line-clamp-3')
const newsSliced = computed(() => {
  // TODO: check strapi config to EP return as Date object instead of string
  const sortedNews = [...(news.value || [])].sort((a: News, b: News) => (b.date as unknown as string).localeCompare(a.date as unknown as string))
  return sortedNews.slice(0, maxNews.value)
})

const date = (date: Date) => useDateFormat(date, APP_DATE_FORMAT)

const handleMoreNews = () => (maxNews.value += MORE_NEWS)

function openDetail(id: number) {
  isDetailOpened.value = true
  openedDetail.value = news.value?.find((message: News) => message.id === id)
  useSidesheetHashHandler(id, isDetailOpened)
}

const showMoreNews = computed(() => !(maxNews.value >= (news.value?.length || 0)))
</script>

<template>
  <BaseSectionBox
    :id="id"
    :button="{
      label: $t('news.loadMore'),
      isVisible: showMoreNews,
    }"
    :title="sectionTitle"
    @open:sidesheet="handleMoreNews"
  >
    <div v-if="news?.length" class="mb-5 mt-7 space-y-5 divide-y divide-solid divide-grey-stroke">
      <div
        v-for="(message, key) in newsSliced"
        :key="key"
        class=""
      >
        <div class="mt-6 flex cursor-pointer flex-row" @click="openDetail(message.id)">
          <div class="flex w-10">
            <Message2Icon class="text-[28px] text-secondary" />
          </div>

          <div class="flex-1">
            <CfgTypography
              class="!font-highlighted mb-2"
              :size="CfgTypographySizes.md"
              tag-name="h3"
            >
              {{ message.title }}
            </CfgTypography>

            <ClientOnly>
              <CfgTypography
                class="mb-3"
                :class="lineClampByDevice"
                :size="CfgTypographySizes.sm"
                tag-name="p"
              >
                {{ message.description }}
              </CfgTypography>
            </ClientOnly>

            <div class="flex flex-row">
              <CfgTypography
                class="!font-highlighted text-secondary"
                :size="CfgTypographySizes.sm"
                tag-name="p"
              >
                {{ date(message.date).value }}
              </CfgTypography>

              <div class="ml-auto">
                <CfgButton
                  class="cursor-pointer underline hover:no-underline"
                  flat
                  text
                  variant="primary"
                  @click="openDetail(message.id)"
                >
                  {{ $t('news.showMore') }}
                </CfgButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <CfgTypography

        :size="CfgTypographySizes.md"
        tag-name="p"
      >
        {{ t('news.noData') }}
      </CfgTypography>
    </div>
  </BaseSectionBox>

  <CfgSlideOver
    v-model:opened="isDetailOpened"
    v-bind="{
      ...(!responsive.mobile ? { width: '50vw' } : {}),
    }"
  >
    <template #head>
      <CfgTypography
        class="block"
        :size="CfgTypographySizes.h2"
        tag-name="h2"
      >
        {{ t('news.title') }}
      </CfgTypography>

      <span
        class="mt-2 block text-grey-300"
      >
        {{ issueName || companyName }}
      </span>
    </template>

    <template #default>
      <div class="mt-2 flex flex-col space-y-5">
        <span
          class="block text-grey-300"
        >
          {{ openedDetail?.date ? date(openedDetail?.date).value : '' }}
        </span>

        <CfgTypography
          class=" block"
          :size="CfgTypographySizes.h2"
          tag-name="h2"
        >
          {{ openedDetail?.title }}
        </CfgTypography>

        <div class="w-full max-w-[765px] whitespace-pre-line pb-20">
          {{ openedDetail?.description }}
        </div>
      </div>
    </template>
  </CfgSlideOver>
</template>
